/* tslint:disable */
/* eslint-disable */
/**
 * Dataland Document Manager API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../../../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../../../../../base';
// @ts-ignore
import type { DocumentMetaInfo } from '../../../../../../org/dataland/datalandfrontend/openApiClient/documentmanager/model';
// @ts-ignore
import type { DocumentMetaInfoEntity } from '../../../../../../org/dataland/datalandfrontend/openApiClient/documentmanager/model';
// @ts-ignore
import type { DocumentMetaInfoPatch } from '../../../../../../org/dataland/datalandfrontend/openApiClient/documentmanager/model';
// @ts-ignore
import type { DocumentMetaInfoResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/documentmanager/model';
// @ts-ignore
import type { ErrorResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/documentmanager/model';
/**
 * DocumentControllerApi - axios parameter creator
 * @export
 */
export const DocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check for a given document ID (hash) if the document already exists in the database.
         * @summary Check if a document exists.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDocument: async (documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('checkDocument', 'documentId', documentId)
            const localVarPath = `/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receive a document by its ID from internal storage.
         * @summary Receive a document.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocument', 'documentId', documentId)
            const localVarPath = `/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receive meta information for a document by its ID from internal storage.
         * @summary Receive meta information for a document.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentMetaInformation: async (documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentMetaInformation', 'documentId', documentId)
            const localVarPath = `/{documentId}/metadata`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the metadata info of a document.
         * @summary Update the metadata info of a document.
         * @param {string} documentId 
         * @param {DocumentMetaInfoPatch} documentMetaInfoPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDocumentMetaInfo: async (documentId: string, documentMetaInfoPatch: DocumentMetaInfoPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('patchDocumentMetaInfo', 'documentId', documentId)
            // verify required parameter 'documentMetaInfoPatch' is not null or undefined
            assertParamExists('patchDocumentMetaInfo', 'documentMetaInfoPatch', documentMetaInfoPatch)
            const localVarPath = `/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentMetaInfoPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Extend the list of companyIds related to a document by a single company id.
         * @summary Extend the list of companyIds related to a document.
         * @param {string} documentId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDocumentMetaInfoCompanyIds: async (documentId: string, companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('patchDocumentMetaInfoCompanyIds', 'documentId', documentId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('patchDocumentMetaInfoCompanyIds', 'companyId', companyId)
            const localVarPath = `/{documentId}/companies/{companyId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a document and meta information.
         * @summary Upload a document and metadata.
         * @param {File} document 
         * @param {DocumentMetaInfo} [documentMetaInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocument: async (document: File, documentMetaInfo?: DocumentMetaInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document' is not null or undefined
            assertParamExists('postDocument', 'document', document)
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


            if (document !== undefined) { 
                localVarFormParams.append('document', document as any);
            }
    
            if (documentMetaInfo !== undefined) { 
                localVarFormParams.append('documentMetaInfo', new Blob([JSON.stringify(documentMetaInfo)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search for document meta information by company ID, document categories and reporting period. Results are returned sorted by publication date in reverse chronological order. Only resultswith QA status \'Accepted\' are returned.
         * @summary Search for document meta information.
         * @param {string} [companyId] 
         * @param {Set<SearchForDocumentMetaInformationDocumentCategoriesEnum>} [documentCategories] 
         * @param {string} [reportingPeriod] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchForDocumentMetaInformation: async (companyId?: string, documentCategories?: Set<SearchForDocumentMetaInformationDocumentCategoriesEnum>, reportingPeriod?: string, chunkSize?: number, chunkIndex?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (documentCategories) {
                localVarQueryParameter['documentCategories'] = Array.from(documentCategories);
            }

            if (reportingPeriod !== undefined) {
                localVarQueryParameter['reportingPeriod'] = reportingPeriod;
            }

            if (chunkSize !== undefined) {
                localVarQueryParameter['chunkSize'] = chunkSize;
            }

            if (chunkIndex !== undefined) {
                localVarQueryParameter['chunkIndex'] = chunkIndex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentControllerApi - functional programming interface
 * @export
 */
export const DocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Check for a given document ID (hash) if the document already exists in the database.
         * @summary Check if a document exists.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkDocument(documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkDocument(documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.checkDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Receive a document by its ID from internal storage.
         * @summary Receive a document.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.getDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Receive meta information for a document by its ID from internal storage.
         * @summary Receive meta information for a document.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentMetaInformation(documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentMetaInfoEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentMetaInformation(documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.getDocumentMetaInformation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update the metadata info of a document.
         * @summary Update the metadata info of a document.
         * @param {string} documentId 
         * @param {DocumentMetaInfoPatch} documentMetaInfoPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDocumentMetaInfo(documentId: string, documentMetaInfoPatch: DocumentMetaInfoPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentMetaInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDocumentMetaInfo(documentId, documentMetaInfoPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.patchDocumentMetaInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Extend the list of companyIds related to a document by a single company id.
         * @summary Extend the list of companyIds related to a document.
         * @param {string} documentId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDocumentMetaInfoCompanyIds(documentId: string, companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentMetaInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDocumentMetaInfoCompanyIds(documentId, companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.patchDocumentMetaInfoCompanyIds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upload a document and meta information.
         * @summary Upload a document and metadata.
         * @param {File} document 
         * @param {DocumentMetaInfo} [documentMetaInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDocument(document: File, documentMetaInfo?: DocumentMetaInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentMetaInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDocument(document, documentMetaInfo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.postDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Search for document meta information by company ID, document categories and reporting period. Results are returned sorted by publication date in reverse chronological order. Only resultswith QA status \'Accepted\' are returned.
         * @summary Search for document meta information.
         * @param {string} [companyId] 
         * @param {Set<SearchForDocumentMetaInformationDocumentCategoriesEnum>} [documentCategories] 
         * @param {string} [reportingPeriod] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchForDocumentMetaInformation(companyId?: string, documentCategories?: Set<SearchForDocumentMetaInformationDocumentCategoriesEnum>, reportingPeriod?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentMetaInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchForDocumentMetaInformation(companyId, documentCategories, reportingPeriod, chunkSize, chunkIndex, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.searchForDocumentMetaInformation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentControllerApi - factory interface
 * @export
 */
export const DocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentControllerApiFp(configuration)
    return {
        /**
         * Check for a given document ID (hash) if the document already exists in the database.
         * @summary Check if a document exists.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDocument(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.checkDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Receive a document by its ID from internal storage.
         * @summary Receive a document.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Receive meta information for a document by its ID from internal storage.
         * @summary Receive meta information for a document.
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentMetaInformation(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<DocumentMetaInfoEntity> {
            return localVarFp.getDocumentMetaInformation(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the metadata info of a document.
         * @summary Update the metadata info of a document.
         * @param {string} documentId 
         * @param {DocumentMetaInfoPatch} documentMetaInfoPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDocumentMetaInfo(documentId: string, documentMetaInfoPatch: DocumentMetaInfoPatch, options?: RawAxiosRequestConfig): AxiosPromise<DocumentMetaInfoResponse> {
            return localVarFp.patchDocumentMetaInfo(documentId, documentMetaInfoPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Extend the list of companyIds related to a document by a single company id.
         * @summary Extend the list of companyIds related to a document.
         * @param {string} documentId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDocumentMetaInfoCompanyIds(documentId: string, companyId: string, options?: RawAxiosRequestConfig): AxiosPromise<DocumentMetaInfoResponse> {
            return localVarFp.patchDocumentMetaInfoCompanyIds(documentId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a document and meta information.
         * @summary Upload a document and metadata.
         * @param {File} document 
         * @param {DocumentMetaInfo} [documentMetaInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocument(document: File, documentMetaInfo?: DocumentMetaInfo, options?: RawAxiosRequestConfig): AxiosPromise<DocumentMetaInfoResponse> {
            return localVarFp.postDocument(document, documentMetaInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Search for document meta information by company ID, document categories and reporting period. Results are returned sorted by publication date in reverse chronological order. Only resultswith QA status \'Accepted\' are returned.
         * @summary Search for document meta information.
         * @param {string} [companyId] 
         * @param {Set<SearchForDocumentMetaInformationDocumentCategoriesEnum>} [documentCategories] 
         * @param {string} [reportingPeriod] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchForDocumentMetaInformation(companyId?: string, documentCategories?: Set<SearchForDocumentMetaInformationDocumentCategoriesEnum>, reportingPeriod?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<DocumentMetaInfoResponse>> {
            return localVarFp.searchForDocumentMetaInformation(companyId, documentCategories, reportingPeriod, chunkSize, chunkIndex, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentControllerApi - interface
 * @export
 * @interface DocumentControllerApi
 */
export interface DocumentControllerApiInterface {
    /**
     * Check for a given document ID (hash) if the document already exists in the database.
     * @summary Check if a document exists.
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApiInterface
     */
    checkDocument(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receive a document by its ID from internal storage.
     * @summary Receive a document.
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApiInterface
     */
    getDocument(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

    /**
     * Receive meta information for a document by its ID from internal storage.
     * @summary Receive meta information for a document.
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApiInterface
     */
    getDocumentMetaInformation(documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<DocumentMetaInfoEntity>;

    /**
     * Update the metadata info of a document.
     * @summary Update the metadata info of a document.
     * @param {string} documentId 
     * @param {DocumentMetaInfoPatch} documentMetaInfoPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApiInterface
     */
    patchDocumentMetaInfo(documentId: string, documentMetaInfoPatch: DocumentMetaInfoPatch, options?: RawAxiosRequestConfig): AxiosPromise<DocumentMetaInfoResponse>;

    /**
     * Extend the list of companyIds related to a document by a single company id.
     * @summary Extend the list of companyIds related to a document.
     * @param {string} documentId 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApiInterface
     */
    patchDocumentMetaInfoCompanyIds(documentId: string, companyId: string, options?: RawAxiosRequestConfig): AxiosPromise<DocumentMetaInfoResponse>;

    /**
     * Upload a document and meta information.
     * @summary Upload a document and metadata.
     * @param {File} document 
     * @param {DocumentMetaInfo} [documentMetaInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApiInterface
     */
    postDocument(document: File, documentMetaInfo?: DocumentMetaInfo, options?: RawAxiosRequestConfig): AxiosPromise<DocumentMetaInfoResponse>;

    /**
     * Search for document meta information by company ID, document categories and reporting period. Results are returned sorted by publication date in reverse chronological order. Only resultswith QA status \'Accepted\' are returned.
     * @summary Search for document meta information.
     * @param {string} [companyId] 
     * @param {Set<SearchForDocumentMetaInformationDocumentCategoriesEnum>} [documentCategories] 
     * @param {string} [reportingPeriod] 
     * @param {number} [chunkSize] 
     * @param {number} [chunkIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApiInterface
     */
    searchForDocumentMetaInformation(companyId?: string, documentCategories?: Set<SearchForDocumentMetaInformationDocumentCategoriesEnum>, reportingPeriod?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<DocumentMetaInfoResponse>>;

}

/**
 * DocumentControllerApi - object-oriented interface
 * @export
 * @class DocumentControllerApi
 * @extends {BaseAPI}
 */
export class DocumentControllerApi extends BaseAPI implements DocumentControllerApiInterface {
    /**
     * Check for a given document ID (hash) if the document already exists in the database.
     * @summary Check if a document exists.
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public checkDocument(documentId: string, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).checkDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Receive a document by its ID from internal storage.
     * @summary Receive a document.
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getDocument(documentId: string, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Receive meta information for a document by its ID from internal storage.
     * @summary Receive meta information for a document.
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getDocumentMetaInformation(documentId: string, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getDocumentMetaInformation(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the metadata info of a document.
     * @summary Update the metadata info of a document.
     * @param {string} documentId 
     * @param {DocumentMetaInfoPatch} documentMetaInfoPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public patchDocumentMetaInfo(documentId: string, documentMetaInfoPatch: DocumentMetaInfoPatch, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).patchDocumentMetaInfo(documentId, documentMetaInfoPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Extend the list of companyIds related to a document by a single company id.
     * @summary Extend the list of companyIds related to a document.
     * @param {string} documentId 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public patchDocumentMetaInfoCompanyIds(documentId: string, companyId: string, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).patchDocumentMetaInfoCompanyIds(documentId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a document and meta information.
     * @summary Upload a document and metadata.
     * @param {File} document 
     * @param {DocumentMetaInfo} [documentMetaInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public postDocument(document: File, documentMetaInfo?: DocumentMetaInfo, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).postDocument(document, documentMetaInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search for document meta information by company ID, document categories and reporting period. Results are returned sorted by publication date in reverse chronological order. Only resultswith QA status \'Accepted\' are returned.
     * @summary Search for document meta information.
     * @param {string} [companyId] 
     * @param {Set<SearchForDocumentMetaInformationDocumentCategoriesEnum>} [documentCategories] 
     * @param {string} [reportingPeriod] 
     * @param {number} [chunkSize] 
     * @param {number} [chunkIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public searchForDocumentMetaInformation(companyId?: string, documentCategories?: Set<SearchForDocumentMetaInformationDocumentCategoriesEnum>, reportingPeriod?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).searchForDocumentMetaInformation(companyId, documentCategories, reportingPeriod, chunkSize, chunkIndex, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const SearchForDocumentMetaInformationDocumentCategoriesEnum = {
    AnnualReport: 'AnnualReport',
    SustainabilityReport: 'SustainabilityReport',
    Policy: 'Policy',
    Other: 'Other'
} as const;
export type SearchForDocumentMetaInformationDocumentCategoriesEnum = typeof SearchForDocumentMetaInformationDocumentCategoriesEnum[keyof typeof SearchForDocumentMetaInformationDocumentCategoriesEnum];
