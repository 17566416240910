/* tslint:disable */
/* eslint-disable */
/**
 * Dataland Backend API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const DataTypeEnum = {
    Sfdr: 'sfdr',
    EutaxonomyFinancials: 'eutaxonomy-financials',
    EutaxonomyNonFinancials: 'eutaxonomy-non-financials',
    NuclearAndGas: 'nuclear-and-gas',
    P2p: 'p2p',
    Lksg: 'lksg',
    AdditionalCompanyInformation: 'additional-company-information',
    Vsme: 'vsme',
    EsgDatenkatalog: 'esg-datenkatalog',
    Heimathafen: 'heimathafen'
} as const;

export type DataTypeEnum = typeof DataTypeEnum[keyof typeof DataTypeEnum];



